import * as O from 'fp-ts/Ord';
import { DateOnly } from 'src/types/DateOnly';
import { Integer } from 'src/types/Integer';

export function fromDate(date: Date): DateOnly {
  return {
    yy: date.getFullYear(),
    mm: date.getMonth() + 1,
    dd: date.getDate(),
  };
}

export function toDate(date: DateOnly): Date {
  return new Date(date.yy, date.mm - 1, date.dd, 0, 0, 0, 0);
}

export function getDaysInMonth(yy: Integer, mm: Integer): Integer {
  // new Date(2024, 8, 0) == new Date(2024, 7, 31)
  return new Date(yy, mm, 0, 0, 0, 0, 0).getDate();
}

export function isValid(date: DateOnly): boolean {
  if (date.yy < 1000 || date.yy > 3000) {
    return false;
  }
  if (date.mm < 1 || date.mm > 12) {
    return false;
  }
  if (date.dd < 1 || date.dd > getDaysInMonth(date.yy, date.mm)) {
    return false;
  }

  return true;
}

export const Ord: O.Ord<DateOnly> = {
  compare: (a, b) => {
    if (a.yy < b.yy) {
      return -1;
    }
    if (a.yy > b.yy) {
      return +1;
    }
    if (a.mm < b.mm) {
      return -1;
    }
    if (a.mm > b.mm) {
      return +1;
    }
    if (a.dd < b.dd) {
      return -1;
    }
    if (a.dd > b.dd) {
      return +1;
    }

    return 0;
  },
  equals: (a, b) => (
    a.yy === b.yy &&
    a.mm === b.mm &&
    a.dd === b.dd
  ),
};
