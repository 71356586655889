import './DatePicker.scss';
import classNames from 'classnames';
import { format } from 'date-fns/format';
import { constFalse, constVoid } from 'fp-ts/function';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActiveModifiers, DayPicker } from 'react-day-picker';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FORMAT_DATE_SHORT } from 'src/constants/format';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { getDateLocale } from 'src/selectors/getDateLocale';
import { DateOnly } from 'src/types/DateOnly';
import { dateNow } from 'src/utils/date';
import { fromDate, Ord, toDate } from 'src/utils/dateOnly';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly value: DateOnly | null;
  readonly onBlur?: () => void;
  readonly onChange: (value: DateOnly | null) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly disabledDate?: (date: DateOnly) => boolean;
};

export function DatePicker({
  id,
  name,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  placeholder,
  disabledDate = constFalse,
}: Props): React.ReactElement {
  const intl = useIntl();
  const locale = useSelector(getDateLocale);
  const [showOverlay, toggleOverlay] = useState(false);

  const viewValue = useMemo(() => {
    return value !== null ? format(toDate(value), FORMAT_DATE_SHORT) : '';
  }, [value]);

  const defaultMonth = useMemo(() => {
    const current = value !== null ? value : fromDate(dateNow());
    return toDate({ ...current, dd: 1 });
  }, [value]);

  const isDateSelected = useCallback((date: Date) => {
    return (
      value !== null &&
      Ord.equals(value, fromDate(date))
    );
  }, [value]);

  const isDateDisabled = useCallback((date: Date) => {
    return disabledDate(fromDate(date));
  }, [disabledDate]);

  const handleInputFocus = useCallback(() => {
    toggleOverlay(true);
  }, []);
  const handleSelectDate = useCallback((date: Date, modifiers: ActiveModifiers) => {
    if (modifiers.disabled) {
      return;
    }

    const nextValue = fromDate(date);
    toggleOverlay(false);

    onChange(nextValue);
    onBlur?.();
  }, [onChange, onBlur]);

  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!showOverlay) {
      return constVoid;
    }

    const handler = (event: MouseEvent): void => {
      const wrapper = container.current;
      if (!wrapper) {
        return;
      }

      const target = event.target;
      if (
        target instanceof Element &&
        container.current.contains(target)
      ) {
        return;
      }

      toggleOverlay(false);
      onBlur?.();
    };

    window.addEventListener('click', handler, { passive: true });
    return () => window.removeEventListener('click', handler);
  }, [showOverlay, onBlur]);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div className="sts-ui-form-date-picker" ref={container} data-invalid={invalid}>
      <input
        ref={inputRef}
        id={id}
        name={name}
        type="text"
        className={classNames(
          'sts-ui-form-textbox',
          'sts-ui-form-date-picker__input',
          showOverlay ? 'sts-ui-form-date-picker__input--open' : null,
        )}
        placeholder={placeholder ?? intl.formatMessage({ id: 'FormElements.DatePicker.PleaseEnterDate' })}
        value={viewValue}
        onChange={constVoid}
        onBlur={onBlur}
        onFocus={handleInputFocus}
        readOnly={true}
        disabled={disabled}
      />
      {showOverlay && (
        <div className="sts-ui-form-date-picker__menu">
          <DayPicker
            onDayClick={handleSelectDate}
            selected={isDateSelected}
            disabled={isDateDisabled}
            defaultMonth={defaultMonth}
            weekStartsOn={1}
            showOutsideDays={true}
            locale={locale}
          />
        </div>
      )}
    </div>
  );
}
