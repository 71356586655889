import './CheckoutPersonalTravelerFormView.scss';
import { FieldPath } from '@form-ts/core';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckoutFormLabel } from 'src/components/checkout/CheckoutFormLabel';
import { Col, Row } from 'src/components/common/Grid';
import { BirthDateField } from 'src/forms/fields/BirthDateField';
import { SelectField } from 'src/forms/fields/SelectField';
import { TextboxField } from 'src/forms/fields/TextboxField';
import { FormError } from 'src/forms/types/FormError';
import { CheckoutPersonalFormData, CheckoutTravelerFormData } from 'src/types/CheckoutFormData';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { CountryCode } from 'src/types/CountryCode';
import { DateOnly } from 'src/types/DateOnly';
import { ListOption } from 'src/types/ListOption';
import { ProductCode } from 'src/types/ProductCode';
import { TicketAge } from 'src/types/TicketAge';
import { TicketType } from 'src/types/TicketType';
import { assertNotEmpty } from 'src/utils/assert';
import { formatCheckoutTravelerNameError } from 'src/utils/checkout/formatCheckoutError';
import { dateNow } from 'src/utils/date';
import { fromDate } from 'src/utils/dateOnly';

type Props = {
  readonly field: FieldPath<CheckoutPersonalFormData, FormError, CheckoutTravelerFormData>;
  readonly product: CheckoutProduct;
  readonly tariff: TicketAge;
  readonly customerCountries: ReadonlyArray<ListOption<CountryCode>>;
  readonly travelerCountries: ReadonlyArray<ListOption<CountryCode>>;
};

export function CheckoutPersonalTravelerFormView({
  field,
  product,
  tariff,
  customerCountries,
  travelerCountries,
}: Props): React.ReactElement {
  const fields = assertNotEmpty(
    product.fields[tariff],
    `No traveler fields defined for "${product.code}"/${tariff}`,
    { product, tariff },
  );

  const intl = useIntl();
  const formatNameError = useCallback((error: FormError) => formatCheckoutTravelerNameError(intl, error), [intl]);

  return (
    <div className="sts-ui-checkout-personal-traveler-form-view">
      <div className="sts-ui-checkout-personal-traveler-form-view__title">
        {getTariffName(product.code, tariff)}
      </div>

      {fields.firstName && (
        <div className="sts-ui-checkout-personal-traveler-form-view__field">
          <Row>
            <Col xs={12} sm={2} md={3} lg={3}>
              <div className="sts-ui-checkout-personal-traveler-form-view__label">
                <CheckoutFormLabel
                  field={field.at('firstName')}
                  required={fields.firstName.required}
                >
                  <FormattedMessage id="Form.Checkout.BillingAddress.FirstName"/>
                </CheckoutFormLabel>
              </div>
            </Col>
            <Col xs={12} sm={10} md={5} lg={5}>
              <TextboxField
                type="text"
                field={field.at('firstName')}
                formatError={formatNameError}
              />
            </Col>
          </Row>
        </div>
      )}

      {fields.lastName && (
        <div className="sts-ui-checkout-personal-traveler-form-view__field">
          <Row>
            <Col xs={12} sm={2} md={3} lg={3}>
              <div className="sts-ui-checkout-personal-traveler-form-view__label">
                <CheckoutFormLabel
                  field={field.at('lastName')}
                  required={fields.lastName.required}
                >
                  <FormattedMessage id="Form.Checkout.BillingAddress.LastName"/>
                </CheckoutFormLabel>
              </div>
            </Col>
            <Col xs={12} sm={10} md={5} lg={5}>
              <TextboxField
                type="text"
                field={field.at('lastName')}
                formatError={formatNameError}
              />
            </Col>
          </Row>
        </div>
      )}

      {fields.homeCountryCode && (
        <div className="sts-ui-checkout-personal-traveler-form-view__field">
          <Row>
            <Col xs={12} sm={2} md={3} lg={3}>
              <div className="sts-ui-checkout-personal-traveler-form-view__label">
                <CheckoutFormLabel
                  field={field.at('homeCountryCode')}
                  required={fields.homeCountryCode.required}
                >
                  <FormattedMessage id="Form.Checkout.BillingAddress.CountryOfResidence"/>
                </CheckoutFormLabel>
              </div>
            </Col>
            <Col xs={12} sm={10} md={5} lg={5}>
              <SelectField
                field={field.at('homeCountryCode')}
                options={travelerCountries}
              />
            </Col>
          </Row>
        </div>
      )}

      {fields.homeFullCountryCode && (
        <div className="sts-ui-checkout-personal-traveler-form-view__field">
          <Row>
            <Col xs={12} sm={2} md={3} lg={3}>
              <div className="sts-ui-checkout-personal-traveler-form-view__label">
                <CheckoutFormLabel
                  field={field.at('homeFullCountryCode')}
                  required={fields.homeFullCountryCode.required}
                >
                  <FormattedMessage id="Form.Checkout.BillingAddress.CountryOfResidence"/>
                </CheckoutFormLabel>
              </div>
            </Col>
            <Col xs={12} sm={10} md={5} lg={5}>
              <SelectField
                field={field.at('homeFullCountryCode')}
                options={customerCountries}
              />
            </Col>
          </Row>
        </div>
      )}

      {fields.birthDate && (
        <div className="sts-ui-checkout-personal-traveler-form-view__field">
          <Row>
            <Col xs={12} sm={2} md={3} lg={3}>
              <div className="sts-ui-checkout-personal-traveler-form-view__label">
                <CheckoutFormLabel
                  field={field.at('birthDate')}
                  required={fields.birthDate.required}
                >
                  <FormattedMessage id="Form.Checkout.BillingAddress.DateOfBirth"/>
                </CheckoutFormLabel>
              </div>
            </Col>
            <Col xs={12} sm={10} md={5} lg={5}>
              <BirthDateField
                field={field.at('birthDate')}
                min={fields.birthDate.constraints?.min ?? DEFAULT_MIN_BIRTH_DATE}
                max={fields.birthDate.constraints?.max ?? DEFAULT_MAX_BIRTH_DATE}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

function getTariffName(product: ProductCode, tariff: TicketAge): React.ReactNode {
  const isSwissHalfFareCard = product === TicketType.SWISS_HALF_FARE_CARD;

  switch (tariff) {
  case TicketAge.ADULT:
    return isSwissHalfFareCard
      ? <FormattedMessage id="Form.Checkout.BillingAddress.TariffHalfFareCard"/>
      : <FormattedMessage id="Form.Checkout.BillingAddress.TariffAdult"/>;

  case TicketAge.YOUTH:
    return <FormattedMessage id="Form.Checkout.BillingAddress.TariffYouth"/>;

  case TicketAge.CHILD:
    return isSwissHalfFareCard
      ? <FormattedMessage id="Form.Checkout.BillingAddress.TariffHalfFareFamilyCard"/>
      : <FormattedMessage id="Form.Checkout.BillingAddress.TariffChildren"/>;

  default:
    return null;
  }
}

const DEFAULT_MIN_BIRTH_DATE: DateOnly = {
  yy: 1900,
  mm: 1,
  dd: 1,
};
const DEFAULT_MAX_BIRTH_DATE: DateOnly = pipe(
  dateNow(),
  fromDate,
);
