import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { DateOnly } from 'src/types/DateOnly';
import { isValid } from 'src/utils/dateOnly';

const REGEX = /^\d\d\.\d\d\.\d\d\d\d$/;

const DECODER: D.Decoder<unknown, DateOnly> = pipe(
  D.string,
  D.parse((input) => {
    if (!REGEX.test(input)) {
      return D.failure(input, 'DateOnly');
    }

    const [dd, mm, yy] = input.split('.').map((it) => parseInt(it, 10));
    return isValid({ dd, mm, yy }) ? D.success({ dd, mm, yy }) : D.failure(input, 'DateOnly');
  }),
);

const ENCODER: E.Encoder<string, DateOnly> = {
  encode: (value) => [
    value.dd.toString().padStart(2, '0'),
    value.mm.toString().padStart(2, '0'),
    value.yy.toString().padStart(4, '0'),
  ].join('.'),
};

export const DATE_ONLY: C.Codec<unknown, string, DateOnly> = C.make(
  DECODER,
  ENCODER,
);
