import { Eq, eqStrict } from 'fp-ts/Eq';
import * as O from 'fp-ts/Ord';
import type { Predicate } from 'fp-ts/Predicate';
import type { Refinement } from 'fp-ts/Refinement';
import { STRICT_ORD } from 'src/constants/ord';
import { NAME_INPUT_PATTERN } from 'src/constants/pattern';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import { DateOnly } from 'src/types/DateOnly';
import { isValid } from 'src/utils/dateOnly';

export function required<T>(): FormRule<T | null | undefined> {
  return (value, context) => {
    if (value === null || value === undefined) {
      return [{
        path: context.path.join('.'),
        code: 'required',
        value: value,

        context: {},
        message: 'Value should not be empty',
      }];
    }

    return NO_ERRORS;
  };
}

export function check<T>(validate: Predicate<T>): FormRule<T> {
  return (value, context) => {
    if (validate(value)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'invalid',
      value: value,

      context: {},
      message: 'Value is not valid',
    }];
  };
}

export function equals<T>(to: T, eq: Eq<T> = eqStrict): FormRule<T> {
  return (value, context) => {
    if (eq.equals(value, to)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'equals',
      value: value,

      context: {},
      message: `Value should be equal to "${String(to)}"`,
    }];
  };
}

export function oneOf<T>(list: ReadonlyArray<T>, eq: Eq<T> = eqStrict): FormRule<T> {
  return (value, context) => {
    if (list.some((it) => eq.equals(value, it))) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'required',
      value: value,

      context: {},
      message: `Value should be one of "${list.map(String).join(', ')}"`,
    }];
  };
}

export function nonEmpty(message?: string): FormRule<string> {
  return (value, context) => {
    if (value === '') {
      return [{
        path: context.path.join('.'),
        code: 'required',
        value: value,

        context: {},
        message: message || 'Value should not be empty',
      }];
    }

    return NO_ERRORS;
  };
}

export function minLength(n: number, message?: string): FormRule<string> {
  return (value, context) => {
    if (value.length >= n) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'minLength',
      value: value,

      context: { min: n },
      message: message || `Value should not be shorter than ${n} character(s)`,
    }];
  };
}

export function maxLength(n: number, message?: string): FormRule<string> {
  return (value, context) => {
    if (value.length <= n) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'maxLength',
      value: value,

      context: { max: n },
      message: message || `Value should not be longer than ${n} character(s)`,
    }];
  };
}

export function email(): FormRule<string> {
  // see https://github.com/formsy/formsy-react/blob/0226fab133a25193e4da8deb0932d2c4593ef17b/src/validationRules.ts#L40
  const REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

  return (value, context) => {
    if (value === '' || REGEXP.test(value)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'email',
      value: value,

      context: {},
      message: 'Value is not a valid email address',
    }];
  };
}

export function name(): FormRule<string> {
  // see NAME_INPUT_PATTERN
  const REGEXP = new RegExp(NAME_INPUT_PATTERN);

  return (value, context) => {
    if (value === '' || REGEXP.test(value)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'name',
      value: value,

      context: { pattern: NAME_INPUT_PATTERN },
      message: 'Value is not a valid name',
    }];
  };
}

export function date(): FormRule<DateOnly> {
  return (value, context) => {
    if (isValid(value)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'date',
      value: value,

      context: {},
      message: 'Value is not a valid date',
    }];
  };
}

export function gt<T>(n: T, ord: O.Ord<T> = STRICT_ORD): FormRule<T> {
  return (value, context) => {
    if (O.gt(ord)(value, n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'gt',
      value: value,

      context: { min: String(n) },
      message: `Value should be greater than ${String(n)}`,
    }];
  };
}

export function gte<T>(n: T, ord: O.Ord<T> = STRICT_ORD): FormRule<T> {
  return (value, context) => {
    if (O.geq(ord)(value, n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'gte',
      value: value,

      context: { min: String(n) },
      message: `Value should not be less than ${String(n)}`,
    }];
  };
}

export function lt<T>(n: T, ord: O.Ord<T> = STRICT_ORD): FormRule<T> {
  return (value, context) => {
    if (O.lt(ord)(value, n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'lt',
      value: value,

      context: { max: String(n) },
      message: `Value should be less than ${String(n)}`,
    }];
  };
}

export function lte<T>(n: T, ord: O.Ord<T> = STRICT_ORD): FormRule<T> {
  return (value, context) => {
    if (O.leq(ord)(value, n)) {
      return NO_ERRORS;
    }

    return [{
      path: context.path.join('.'),
      code: 'lte',
      value: value,

      context: { max: String(n) },
      message: `Value should not be greater than ${String(n)}`,
    }];
  };
}

export function allOf<T>(rules: ReadonlyArray<FormRule<T>>): FormRule<T> {
  return (value, context) => rules.reduce((errors, rule) => {
    const more = rule(value, context);

    return more.length > 0
      ? errors.concat(more)
      : errors;
  }, NO_ERRORS);
}

export function anyOf<T>(rules: ReadonlyArray<FormRule<T>>): FormRule<T> {
  return (value, context) => {
    for (const rule of rules) {
      const more = rule(value, context);
      if (more.length === 0) {
        return NO_ERRORS;
      }
    }

    return [{
      path: context.path.join('.'),
      code: 'invalid',
      value: value,

      context: {},
      message: 'Value does not match any rule',
    }];
  };
}

export function struct<T>(rules: { readonly [K in keyof T]: FormRule<T[K]> }): FormRule<T> {
  return (value, context) => {
    let errors = NO_ERRORS;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in rules) {
      // eslint-disable-next-line no-prototype-builtins
      if (!rules.hasOwnProperty(key)) {
        continue;
      }

      const path = context.path.concat(key);
      const rule = rules[key];
      const more = rule(value[key], { path });

      if (more.length > 0) {
        errors = errors.concat(more);
      }
    }

    return errors;
  };
}

export function nullable<T>(rule: FormRule<T>): FormRule<T | null> {
  return (value, context) => (
    value !== null
      ? rule(value, context)
      : NO_ERRORS
  );
}

export function array<T>(rule: FormRule<T>): FormRule<ReadonlyArray<T>> {
  return (value, context) => value.reduce((errors, val, key) => {
    const path = context.path.concat(key.toString());
    const more = rule(val, { path });

    return more.length > 0
      ? errors.concat(more)
      : errors;
  }, NO_ERRORS);
}

export function record<T>(rule: FormRule<T>): FormRule<Readonly<Record<string, T>>> {
  return (value, context) => Object.entries(value).reduce((errors, [key, val]) => {
    const path = context.path.concat(key);
    const more = rule(val, { path });

    return more.length > 0
      ? errors.concat(more)
      : errors;
  }, NO_ERRORS);
}

export function maybe<T, U extends T>(test: Refinement<T, U>, rule: FormRule<U>): FormRule<T>;
export function maybe<T>(test: Predicate<T>, rule: FormRule<T>): FormRule<T> {
  return (value, context) => (test(value)
    ? rule(value, context)
    : NO_ERRORS);
}

export function map<T, U = T>(fn: (value: T) => U, rule: FormRule<U>): FormRule<T> {
  return (value, context) => rule(fn(value), context);
}

export function pass<T>(): FormRule<T> {
  return () => NO_ERRORS;
}

export const NO_ERRORS: ReadonlyArray<FormError> = [];
