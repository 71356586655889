import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import { JsonRecord } from 'fp-ts/Json';
import * as A from 'fp-ts/ReadonlyArray';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import * as R from 'fp-ts/ReadonlyRecord';
import { CURRENCY_GBP } from 'src/constants/currency';
import { BASKET_PRODUCT_ORD } from 'src/constants/ord';
import { BasketProduct } from 'src/types/BasketProduct';
import { CheckoutOrder } from 'src/types/CheckoutOrder';
import { CheckoutTotal } from 'src/types/CheckoutTotal';
import { ProductCode } from 'src/types/ProductCode';
import { ProductMap } from 'src/types/ProductMap';
import { PurchaseEventDefault, PurchaseEventDefaultProduct } from 'src/types/PurchaseEvent';
import { isNotEmpty, isNotNull } from 'src/utils/guard';

export function createPurchaseEventDefault(
  order: CheckoutOrder,
  prices: CheckoutTotal,
  products: ProductMap<BasketProduct>,
): PurchaseEventDefault {
  const eventProducts = pipe(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    products as R.ReadonlyRecord<ProductCode, BasketProduct>,
    R.toReadonlyArray,
    A.map(([code, product]) => ({ code, ...product })),
    A.sort(BASKET_PRODUCT_ORD),
    A.map((product): PurchaseEventDefaultProduct | null => {
      const price = prices.ticketPrices[product.code];
      if (!price) {
        return null;
      }

      return {
        id: product.code,
        name: product.code,
        price: price.reduce((sum, it) => sum.add(it.price), new Decimal(0)).toNumber(),
        quantity: 1,
      };
    }),
    A.filter(isNotNull),
  );

  return {
    event: 'sts.purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: order.data.refno,
          affiliation: `Affiliate #${order.data.affiliateId}`,
          currency: order.data.currency,
          revenue: order.data.amount.toNumber(),
          coupon: '',
        },
        products: eventProducts,
      },
    },
  };
}

export function createPurchaseEventCustom(
  order: CheckoutOrder,
  prices: CheckoutTotal,
  products: ProductMap<BasketProduct>,
  customParams: R.ReadonlyRecord<string, unknown>,
): JsonRecord {
  const totalProducts = pipe(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    products as R.ReadonlyRecord<ProductCode, BasketProduct>,
    R.toReadonlyArray,
    A.map(([code]) => prices.ticketPrices[code]),
    A.filter(isNotEmpty),
    A.flatten,
    A.reduce(new Decimal(0), (sum, product) => sum.add(product.price)),
  );
  const templateVariables = {
    order_id: order.data.refno,
    total: order.data.amount.toFixed(2),
    total_products: totalProducts.toFixed(2),
    vignette: 'false',
    currency_code: order.data.currency === CURRENCY_GBP ? CURRENCY_GBP : 'CHF',
  };

  return pipe(
    customParams,
    R.map(String),
    R.map(replaceAll(templateVariables)),
  );
}

function replaceAll(vars: ReadonlyRecord<string, string>): (template: string) => string {
  return (template) => Object.entries(vars).reduce((result, [tplKey, tplVal]) => {
    const regexp = new RegExp(`{{\\s*${tplKey}\\s*}}`);
    return result.replace(regexp, String(tplVal));
  }, template);
}
